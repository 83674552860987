<template>
  <v-autocomplete
    v-model="selection"
    :items="diagnosis_groups"
    :loading="loading"
    item-text="name"
    item-value="id"
    return-object
    v-bind="$attrs"
    menu-props="offsetY"
    @change="$emit('update:value', selection)"
  >
    <template
      v-if="selection.length > 1"
      #selection="{ item }"
    >
      <v-chip
        x-small
        class="ml-0"
        v-text="item.name"
      />
    </template>
  </v-autocomplete>
</template>
<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => ([]),
    },
  },
  data () {
    return {
      loading: false,
      search: null,
      selection: this.value,
      diagnosis_groups: [],
    }
  },
  watch: {
    search (term) {
      if (!this.selection || term != this.selection.name) {
        this.fetchDiagnosisGroups(term)
      }
    },
    '$attrs.value.id' () {
      this.diagnosis_groups.push(this.$attrs.value)
      this.selection = this.$attrs.value
    },
  },
  created () {
    if (!this.diagnosis_groups.length) {
      this.fetchDiagnosisGroups(this.selection?.id ? this.selection.name : null)
    } else {
      this.fetchDiagnosisGroups()
    }
  },
  methods: {
    fetchDiagnosisGroups (search) {
      this.loading = true
      this.axios.get('admin/diagnoses/groups', { params: { search: search } })
        .then((res) => {
          this.diagnosis_groups = res.data
        })
        .catch(error => {
          if (error.response?.data?.message) {
            this.$toast.error(error.response.data.message)
          }
        })
        .finally(() => { this.loading = false })
    },
  },
}
</script>
